import { request } from "/src/utils/index.js";

export function Search(params) {
  // console.log(parmas);
  return request.get("sdbs/product/list", { params });
}

export function Type(params) {
  // console.log(parmas);
  return request.get("sdbs/product/category/list", { params });
}

export function Add(params) {
  return request.post("batch/upload/task/create", params);
}
