<template>
  <div>
    <div>
      <div class="searchArea">
        <a-radio-group v-model:value="inputs.category" @change="detail" size="large" style="margin-bottom:20px;">
          <a-radio-button v-for="item in type" :value="item.name">{{item.text}}</a-radio-button>
        </a-radio-group>
        <br>
        <a-input v-model:value="inputs.name" placeholder="药名" />
        <a-select v-model:value="inputs.vendorId" placeholder="供应商" allowClear>
          <a-select-option v-for="item in vendor" :value="item.id">{{item.name}}</a-select-option>
        </a-select>
        <a-button type="primary" shape="circle" @click="search('')">
          <template #icon>
            <SearchOutlined />
          </template>
        </a-button>
        <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
        <a-button style="margin-left: 10px" type="primary" @click="visible=true">
          <template #icon>
            <PlusOutlined />
          </template>
          批量上传
        </a-button>
      </div>
      <a-table class="ant-table-striped" :columns="columns" :data-source="data" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" bordered rowKey="name" :pagination="{ pageSize ,total,current,showTotal: (total) => `共 ${total} 条`,showSizeChanger:true,pageSizeOptions: ['10', '15', '20', '30', '50']}" :scroll="{ y: 500 }" @change="search">
        <template #operation="{ record }">
          <div class="flex_sa">
            <div class="editable-row-operations flex_sa" v-if="!record.isAlias" style="width: 100px;">
              <span v-if="editableData[record.id]">
                <a @click="save(record.id)">保存</a>
              </span>
              <span v-if="editableData[record.id]">
                <a @click="cancel(record.id)">取消</a>
              </span>
              <span v-else>
                <a @click="edit(record.id)">编辑</a>
              </span>
            </div>
            <div class="editable-row-operations" v-if="!record.isAlias">
              <span>
                <a-popconfirm v-if="data.length" title="是否确认删除" @confirm="onDelete(record.id)" okText="确认" cancelText="取消">
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </div>
          </div>
        </template>

        <template v-for="col in ['percent']" #[col]="{ text, record }" :key="col">
          <div>{{record.ratioGranule}}：{{record.ratioHerbal}}</div>
        </template>
        <template v-for="col in ['category']" #[col]="{ text, record }" :key="col">
          <div>{{text.text}}</div>
        </template>
        <template v-for="col in ['famount']" #[col]="{ text, record }" :key="col">
          <div>{{record.amountPerPackageUnit}}克/{{record.packageUnit}}</div>
        </template>

      </a-table>

    </div>
    <!-- 详情 -->
    <addProduct v-model="visible" :record="selectedRow" @getTask="getTask" />
    <getTask v-model="visible2" :record="taskInfo" />
  </div>
</template>


<style scoped>
.online {
  background: #40a9ff;
  border-color: #40a9ff;
}
.offline {
  background: #979596;
  border-color: #979596;
}
:deep(.ant-table-tbody > tr > td, .ant-table-thead > tr > th) {
  padding: 10px 8px;
}
.table_btn {
  width: 70px;
  display: inline-block;
  text-align: center;
}
</style>


<script>
import {
  SearchOutlined,
  PlusOutlined,
  DownloadOutlined,
  CheckCircleFilled,
  WarningFilled,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons-vue";
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { Search, Type } from "/src/api/product.js";
import { Search as getVendor } from "/src/api/vendor.js";
import addProduct from '/src/components/product/addProduct.vue';
import getTask from '/src/components/task/getTask.vue';

export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    DownloadOutlined,
    locale,
    CheckCircleFilled,
    WarningFilled,
    addProduct,
    CheckOutlined,
    CloseOutlined,
    getTask
  },
  name: "药品管理",
  data() {
    return {
      modalBtn: "",
      modalIndex: "0",
      visible: false,
      taskInfo: {},
      type: [],
      vendor: [],
      visible2: false,
      active: undefined,
      id: undefined,
      total: 0,
      current: 1,
      pageSize: 10,
      inputs: {
        name: undefined,
        category: "",
        vendorId: undefined,
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      columns: [
        {
          title: "药品",
          key: "name",
          dataIndex: "name",
          width: "25%",
          slots: {
            customRender: "name"
          }
        },
        {
          title: "编号",
          key: "code",
          dataIndex: "code",
          ellipsis: true,
          width: "15%",
          slots: {
            customRender: "code"
          },
        },
        {
          title: "供应商",
          key: "vendorName",
          dataIndex: "vendorName",
          ellipsis: true,
          width: "15%",
          slots: {
            customRender: "vendorName"
          },
        },
        {
          title: "规格",
          key: "famount",
          dataIndex: "famount",
          ellipsis: true,
          width: "15%",
          slots: {
            customRender: "famount"
          },
        },
        {
          title: "采购价",
          key: "purchaseUnitPriceActual",
          dataIndex: "purchaseUnitPriceActual",
          ellipsis: true,
          width: "15%",
          slots: {
            customRender: "purchaseUnitPriceActual"
          },
        },
        {
          title: "浓缩比",
          width: "15%",
          key: "percent",
          dataIndex: "percent",
          slots: {
            customRender: "percent"
          }
        },
        {
          title: "单价",
          width: "15%",
          key: "price",
          dataIndex: "price",
          slots: {
            customRender: "price"
          }
        },
        {
          title: "类型",
          width: "15%",
          key: "category",
          dataIndex: "category",
          slots: {
            customRender: "category"
          }
        },
        {
          title: "库存",
          width: "15%",
          key: "totalAmount",
          dataIndex: "totalAmount",
          slots: {
            customRender: "totalAmount"
          }
        },
        // {
        //   title: "操作",
        //   dataIndex: "operation",
        //   width: "15%",
        //   slots: {
        //     customRender: "operation"
        //   }
        // }
      ],
      data: [],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          this.selectedRows = selectedRows;
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        }
      },
      loading: true,
      editableData: reactive({}),
      selectedRow: "",
      selectedRows: {}
      // doExcel: doExcel
    };
  },
  created() { },
  mounted() {
    this.init()
  },
  activated() {

  },
  methods: {
    resetForm() {
      this.init()
    },
    async init() {
      let res = await Type()
      this.type = res.data
      this.inputs.category = res.data[0].name

      let res2 = await getVendor()
      this.vendor = res2.data
      // this.inputs.vendorId = res2.data[0].id


      this.search("");
    },
    async onDelete(id) {
      this.editableData = {}
      let res = await Delete({ id })
      this.data = this.data.filter(function (item) {
        return item.id !== id;
      });
    },
    edit(id) {
      let _data = this.data;
      this.editableData[id] = cloneDeep(
        _data.filter(item => id === item.id)[0]
      );
    },
    getTask(record) {
      this.taskInfo = { ...record }
      this.visible2 = true
    },
    async save(id) {
      let _data = this.data;

      //判断editableData和修改前是否一致，如果一致则不修改
      if (JSON.stringify(this.data.find((item) => item.id == id)) === JSON.stringify(this.editableData[id])) {
        delete this.editableData[id];
        return;
      }
      let params = {
        id: this.editableData[id].id,
        name: this.editableData[id].name,
        nameInitial: this.editableData[id].nameInitial,
        gender: this.editableData[id].gender,
      }
      let res = await Update(params)
      if (res.errCode == 0) {
        Object.assign(
          _data.filter(item => id === item.id)[0],
          this.editableData[id]
        );
        delete this.editableData[id];
      }
    },
    cancel(id) {
      delete this.editableData[id];
    },
    tabsName(tabName) {
      this.$store.commit("changePage", { name: tabName.name, query: tabName.query });
    },
    async search(pagination) {
      console.log(pagination);
      this.pageSize = pagination.pageSize
      let _this = this;
      this.editableData = {}
      let params = {
        name: this.inputs.name,
        category: this.inputs.category,
        vendorId: this.inputs.vendorId,
        pageSize: this.pageSize,
        includeInventory: true
      };
      if (pagination == "") {
        params.current = 1
      } else {
        params.current = pagination.current
        this.current = pagination.current;
      }
      this.columns = this.columns.filter(item => item.title !== "统编编码")
      if (this.inputs.category == 'Caoyao') {
        this.columns.unshift({
          title: "统编编码",
          key: "medicalInsuranceCode",
          dataIndex: "medicalInsuranceCode",
          width: "15%",
          slots: {
            customRender: "medicalInsuranceCode"
          }
        })
      }
      let res = await Search(params)
      if (res.errCode == 0) {
        if (pagination == "") {
          // this.$message.success("查询成功", function () { });
          this.current = 1;
        }
        this.total = res.data.total;
        this.data = res.data.records;
      } else {
        this.$message.error("查询失败", function () { });
      }
      // this.data = res
    },



  }
};
</script>