<template>
  <div>
    <a-modal v-model:visible="visible" title="新增药品" :afterClose="closeModal" @ok="handleOk" okText="新增" cancelText="关闭" :centered="true" class="hideClose">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="类型">
          <a-radio-group v-model:value="data.productCategory" @change="handleChange($event)" size="normal" class="radio">
            <a-radio-button v-for="(item,i) in type" :value="item.name">
              <div class="radioText">
                {{item.text}}
                <div class="tag" v-if="visibles[i]">
                  <div class="title">该类型上传可选字段<span style="color:#cf1322">(红色必要字段)</span>
                  </div>
                  <div class="content">
                    <div v-if="item.text=='颗粒剂'">
                      <a-tag color="red">名称（品名，饮片名，药名）</a-tag>
                      <a-tag color="red">价格（零售价、每克颗粒零售价）</a-tag>
                      <a-tag color="red">饮片当量</a-tag>
                      <a-tag color="green">颗粒剂当量</a-tag>
                      <a-tag color="green">包装单位</a-tag>
                      <a-tag color="green">规格</a-tag>
                      <a-tag color="green">采购价</a-tag>
                    </div>
                    <div v-else-if="item.text=='草药'">
                      <a-tag color="red">统编编码</a-tag>
                      <a-tag color="red">名称</a-tag>
                      <a-tag color="red">价格（零售价、每克颗粒零售价）</a-tag>
                      <!-- <a-tag color="green">包装单位</a-tag> -->
                      <a-tag color="green">单位</a-tag>
                      <a-tag color="green">采购价</a-tag>
                    </div>
                    <div v-else>
                      <a-tag color="pink">无</a-tag>
                    </div>
                    <h3 v-if="visible2" style="margin-top: 10px;">如：</h3>
                    <a-table v-if="visible2" class="ant-table-striped" :columns="columns" :data-source="tableData" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" bordered rowKey="id" :pagination="false" style="margin-top: 10px;width:35vw">
                    </a-table>
                  </div>
                </div>
              </div>
            </a-radio-button>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="供应商">
          <a-select v-model:value="data.vendorId">
            <a-select-option v-for="item in vendor" :value="item.id">{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="上传">
          <label for="file">
            <input type="file" id="file" accept=".xlsx, .xls" />
          </label>
        </a-form-item>
        <!-- <a-form-item label="上传">
          <a-upload v-model:file-list="data.file" name="file" :multiple="true" action="/api" :headers="headers" @change="handleChange">
            <a-button>
              <upload-outlined></upload-outlined>
              批量上传
            </a-button>
          </a-upload>
        </a-form-item> -->
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// import {  } from "/src/api/medicine.js";
import { Type, Add } from "/src/api/product.js";
import { Search } from "/src/api/vendor.js";
export default {
  components: {
  },
  data() {
    return {
      visible: this.modelValue,
      visibles: [],
      visible2: false,
      type: [],
      vendor: [],
      data: {
        vendorId: "",
        productCategory: "",
        category: "导入药品",
        file: "",
      },
      columns: [],
      tableData: [
        {
          name: "三七花",
          price: "10.41",
          kdl: "1",
          ydl: "3",
          dw: "瓶",
          dw2: "克",
          gg: "100",
          cgj: "5.00",
          code: "S23US232"
        },
        {
          name: "生延胡索",
          price: "5.31",
          kdl: "1",
          ydl: "2",
          dw: "瓶",
          dw2: "克",
          gg: "100",
          cgj: "3.11",
          code: "S23US232"
        },
      ],
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() { },
  mounted() {
    this.init()
  },
  activated() {
  },
  //获取父元素传值
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {

  },
  methods: {
    async init() {
      let res = await Type()
      this.type = res.data

      this.type.forEach(element => {
        this.visibles.push(false)
      });

      let res2 = await Search()
      this.vendor = res2.data
    },
    handleChange(e) {
      this.visibles = []
      this.type.forEach(element => {
        this.visibles.push(false)
      });
      let index = this.type.findIndex((item) => item.name == e.target.value)
      console.log(e.target.value);

      this.visibles[index] = true
      if (e.target.value == 'Keliji') {
        this.visible2 = true
        this.columns = [
          {
            title: "名称",
            key: "name",
            dataIndex: "name",
            width: "10%",
            slots: {
              customRender: "name"
            }
          },
          {
            title: "价格",
            key: "price",
            dataIndex: "price",
            ellipsis: true,
            width: "10%",
            slots: {
              customRender: "price"
            },
          },
          {
            title: "颗粒剂当量",
            width: "10%",
            key: "kdl",
            dataIndex: "kdl",
            slots: {
              customRender: "kdl"
            }
          },
          {
            title: "饮片当量",
            width: "10%",
            key: "ydl",
            dataIndex: "ydl",
            slots: {
              customRender: "ydl"
            }
          },
          {
            title: "包装单位",
            width: "10%",
            key: "dw",
            dataIndex: "dw",
            slots: {
              customRender: "dw"
            }
          },
          {
            title: "规格",
            width: "10%",
            key: "gg",
            dataIndex: "gg",
            slots: {
              customRender: "gg"
            }
          },
          {
            title: "采购价",
            width: "10%",
            key: "cgj",
            dataIndex: "cgj",
            slots: {
              customRender: "cgj"
            }
          },
        ]
      } else if (e.target.value == 'Caoyao') {
        this.visible2 = true
        this.columns = [
          {
            title: "统编编码",
            key: "code",
            dataIndex: "code",
            width: "10%",
            slots: {
              customRender: "code"
            }
          },
          {
            title: "名称",
            key: "name",
            dataIndex: "name",
            width: "10%",
            slots: {
              customRender: "name"
            }
          },
          {
            title: "价格",
            key: "price",
            dataIndex: "price",
            ellipsis: true,
            width: "10%",
            slots: {
              customRender: "price"
            },
          },
          {
            title: "单位",
            width: "10%",
            key: "dw2",
            dataIndex: "dw2",
            slots: {
              customRender: "dw2"
            }
          },
          {
            title: "采购价",
            width: "10%",
            key: "cgj",
            dataIndex: "cgj",
            slots: {
              customRender: "cgj"
            }
          },
        ]
      } else {
        this.visible2 = false
      }
    },
    async handleOk() {
      let _this = this;
      let file = document.getElementById("file").files[0];
      // // 将文件添加到FormData对象中
      let formData = new FormData();
      formData.append('file', file);
      formData.append("vendorId", this.data.vendorId);
      formData.append("category", this.data.category);
      formData.append("productCategory", this.data.productCategory);

      let obj = { ...this.data, file: formData }
      if (!this.data.category || !this.data.productCategory || document.getElementById("file").files.length == 0) {
        _this.$message.error("请填写完整信息", function () { });
        return
      }
      let res = await Add(formData)
      if (res.errCode == 0) {
        _this.$message.success("新建成功", function () { });
        _this.visible = false
        this.data = {
          vendorId: "",
          productCategory: "",
          category: "导入药品",
          file: "",
        }
        document.getElementById("file").value = ""
      }
      console.log(res);

      this.$emit('getTask', res.data);
    },
    closeModal() {
      this.visibles = []
      this.data.productCategory = ""
      this.$emit('update:modelValue', false);

    },

    //初始化data所有数据，并关闭modal
  },
  watch: {
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}
.head span {
  font-weight: bold;
  margin-right: 10px;
}
.ant-radio-button span {
  display: block;
  width: 100%;
  height: 100%;
}
.radio .ant-radio-button span {
  display: block;
  width: 100%;
  height: 100%;
}
.radio .ant-radio-button-wrapper {
  padding: 0 0px;
}
.radioText {
  position: relative;
  padding: 0 15px;
}
.radioText .tag {
  position: absolute;
  z-index: 9999;
  min-width: 250px;
  padding: 10px 10px 20px 10px;
  background: #fff;
  box-shadow: 1px 1px 3px 1px #00000030;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -100%);
}
.radioText .tag::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 12px;
  height: 12px;
  background-color: #fff;
  box-shadow: -1px 1px 1px 0px #00000030;
  /* border: 1px solid #4b8eff; */
  /* border-style: none none solid solid; */
}
.tag .title {
  font-size: 12px;
  color: #000000d9;
  font-weight: 500;
  padding: 0 5px 5px;
  border-bottom: 1px solid #ddd;
}
.tag .content {
  margin-top: 15px;
}
.tag .content > div {
  display: flex;
}
</style>
